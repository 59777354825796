.App {
  
  text-align: center;

}

button{
  background-color: #003F9E;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  
    &:hover {
      background-color: #002e7a; // Slightly darker blue for hover effect
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  
    &:active {
      background-color: #001f56; // Even darker blue for active state
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  
    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(0, 63, 158, 0.5); // Light blue outline for focus
    }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
