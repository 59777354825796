$padding:20px;

.header{
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $padding;
  top:0;
  left:0;
  right:0;
  height: 80px;
  width: calc(100% - $padding - $padding);
  background-color: #003F9E;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
      0 1px 3px rgba(0, 0, 0, 0.08);

  svg{
    // width: 120px;
    width: 120px;
    height: 80px;
  }

  .row{
    display: flex;
    align-items: center;
    
    padding: 0 20px;
    svg{
      padding-right:20px;
    }
  }

}